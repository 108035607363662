<template>
  <TTTextField
    large
    :value="value"
    :placeholder="placeholder"
    :append-icon="appendIcon"
    class="data-list-search"
    clearable
    @input="onInputHandler"
    @keydown.enter="onKeydownEnter"
  />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({

  name: 'InputSearch',
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    appendIcon() {
      return this.value && this.value.length > 0 ? null : '$search';
    },
  },
  methods: {
    onKeydownEnter() {
      this.$emit('keydown-enter');
    },

    onInputHandler(v) {
      this.$emit('input', v);
    },
  },
});
</script>
