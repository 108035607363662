import {
  Ref, isRef, watch, onMounted, onBeforeUnmount, unref,
} from 'vue';

export function useEventListener<T extends EventTarget>(
  target: Ref<T | null> | T,
  event: string,
  handler: EventListenerOrEventListenerObject,
  options: AddEventListenerOptions = {},
) {
  if (isRef(target)) {
    watch(target, (value, oldValue) => {
      oldValue?.removeEventListener(event, handler);
      value?.addEventListener(event, handler, options);
    });
  } else {
    onMounted(() => {
      target.addEventListener(event, handler, options);
    });
  }

  onBeforeUnmount(() => {
    unref(target)?.removeEventListener(event, handler);
  });
}
