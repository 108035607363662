import {
  computed, onMounted, onUnmounted, ref, Ref, watch,
} from 'vue';
import { getRemainingTime } from '@/helpers/time';

export interface UseAlarmProps {
  startedAt: Ref<DateTimeISO8601>;
  maxSecondsCount: Ref<number>;
  autostart?: boolean;
  refreshRateMilliseconds?: number;
  onStart?: (...args: any[]) => any;
  onPause?: (...args: any[]) => any;
  onStop?: (...args: any[]) => any;
}

export const useAlarm = ({
  startedAt,
  maxSecondsCount,
  autostart = false,
  refreshRateMilliseconds = 100,
  onStart = () => {},
  onStop = () => {},
}: UseAlarmProps) => {
  const timeLeft = ref<number | undefined>();

  const isTimeOver = computed(() => timeLeft.value !== null && timeLeft.value === 0);

  let started = false;

  let timerRef: number | undefined;
  let signal: Promise<void> | undefined;
  let resolve: () => void = () => {};
  let reject: () => void = () => {};

  const stopTimer = () => {
    if (started) {
      reject();
    }

    started = false;

    resolve = () => {};
    reject = () => {};

    window.clearInterval(timerRef);
    timerRef = undefined;

    onStop();
  };

  const tick = () => {
    if (timeLeft.value === 0) {
      timeLeft.value = 0;
      started = false;
      resolve();
      stopTimer();
    }

    timeLeft.value = getRemainingTime(startedAt.value, maxSecondsCount.value);
  };

  const startTimer = () => {
    if (signal) {
      return signal;
    }

    stopTimer();

    if (maxSecondsCount.value === 0) {
      return Promise.resolve();
    }

    signal = new Promise<void>((__resolve, __reject) => {
      resolve = __resolve;
      reject = __reject;
    });

    started = true;

    timerRef = window.setInterval(tick, refreshRateMilliseconds);

    tick();

    onStart();

    return signal;
  };

  const pauseTimer = () => {
    window.clearInterval(timerRef);
  };

  if (autostart) {
    startTimer();
  }

  watch([startedAt, maxSecondsCount], () => {
    if (autostart) {
      startTimer();
    }
  });

  onMounted(() => {
    //
  });

  onUnmounted(() => {
    pauseTimer();
  });

  return {
    timeLeft,
    isTimeOver,
    startTimer,
    pauseTimer,
    stopTimer,
  };
};
