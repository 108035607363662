import { useEventListener } from './useEventListener';

export interface KeyDescription {
  key: string;
  fn: (event: Event) => void;
}

export const useKeyDown = (keyDescriptions: KeyDescription[]) => {
  useEventListener(window, 'keydown', (event: Event) => {
    const keyDescription = keyDescriptions.find(({ key }) => key === (event as KeyboardEvent).key);

    if (keyDescription) {
      keyDescription.fn(event);
    }
  });
};
