
import Vue, { PropType } from 'vue';

import Sidebar from '@/components/ui/Sidebar/Sidebar.vue';
import CategoryAsidePicker from '@/components/catalogue/CategoryAsidePicker/CategoryAsidePicker.vue';
import ContentProviderAsidePicker from '@/components/catalogue/ContentProviderAsidePicker/ContentProviderAsidePicker.vue';
import DurationAsidePicker from '@/components/catalogue/DurationAsidePicker/DurationAsidePicker.vue';
import { Category } from '@/domains/category';
import { IContentProvider } from '@/domains/content-provider';

export default Vue.extend({
  name: 'CatalogueSidebar',
  components: {
    Sidebar,
    CategoryAsidePicker,
    ContentProviderAsidePicker,
    DurationAsidePicker,
  },

  props: {
    providers: {
      type: Array as PropType<IContentProvider[]>,
      default: () => ([]),
    },
    categories: {
      type: Array as PropType<Category[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      filters: {
        providers: [],
        categories: [],
        durations: [],
      },
    };
  },
  watch: {
    filters: {
      deep: true,
      async handler() {
        this.update();
      },
    },
  },
  methods: {
    update() {
      this.$emit('input', {
        ...this.filters,
      });
    },
    setDefaultValues() {
      this.filters = {
        providers: [],
        categories: [],
        durations: [],
      };
    },
  },
});
