<template>
  <div>
    <VMenu
      content-class="box-shadow-light"
      min-width="250px"
      bottom
      left
      offset-x
      nudge-left="24"
      data-test="v-menu"
      data-test-label="actions-menu"
    >
      <template #activator="{ on, attrs }">
        <slot
          name="activator"
          :attrs="attrs"
          :on="on"
        >
          <TTBtn
            class="menu-btn pa-0"
            square
            color="tt-ghost"
            plain
            v-bind="attrs"
            v-on="on"
          >
            <VIcon :color="iconColor">
              $ellipsis
            </VIcon>
          </TTBtn>
        </slot>
      </template>

      <VList
        class="menu-list pa-4"
      >
        <slot />
      </VList>
    </VMenu>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'MenuDropdown',
  props: {
    iconColor: {
      type: String,
      default: 'tt-light-blue',
    },
  },
});
</script>

<style lang="scss" scoped>
$border: 1px solid map-get($tt-light-mono-8, 'base');

.menu-list {
  border: $border;
  border-radius: 4px;
}

.menu-btn {
  &.v-btn.tt-btn--square.v-size--default {
    height: 24px;
    width: 24px;
  }

  &.v-btn:not(.v-btn--fab).v-size--default .v-icon {
    font-size: 24px;
  }
}
</style>
