import { ref } from 'vue';

export const useImageLoader = () => {
  const isImageLoading = ref(false);

  const onLoadStartHandler = () => {
    isImageLoading.value = true;
  };

  const onLoadErrorHandler = () => {
    isImageLoading.value = false;
  };

  return {
    isImageLoading,
    onLoadStartHandler,
    onLoadErrorHandler,
  };
};
