import { Ref, ref } from 'vue';

export const useRef = <El extends Element | Vue>() => {
  const reference: Ref<El | null> = ref(null);

  const setRef = (el: El | null) => {
    reference.value = el;
  };

  return [reference, setRef] as const;
};
