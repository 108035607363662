
import Vue, { PropType } from 'vue';
import { getImageUrl } from '@/helpers/gogha/getImageUrl';
import { Category } from '@/domains/category';

export default Vue.extend({
  name: 'CategoryAsidePicker',
  props: {
    value: {
      type: Array as PropType<Category['id'][]>,
      default: () => [] as Category['id'][],
    },
    items: {
      type: Array as PropType<Category[]>,
      default: () => [] as Category[],
    },
    itemsVisibleCount: {
      type: Number as PropType<number>,
      default: 6,
    },
    showTotal: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      search: '',
      expanded: false,
    };
  },
  computed: {
    itemsVisible(): Category[] {
      const query = this.search.toLowerCase();
      const items = this.items.filter((category) => category.title.toLowerCase().includes(query));

      return this.expanded
        ? items
        : items.slice(0, this.itemsVisibleCount);
    },
  },
  methods: {
    toggle(v: Category) {
      if (!this.value.includes(v.id)) {
        this.$emit('input', [...this.value, v.id]);
      } else {
        this.$emit('input', this.value.filter((id) => id !== v.id));
      }
    },
    categoryIconSrc(category: Category): string {
      return getImageUrl({
        uuid: category.id,
        type: 'catalogue',
        name: 'category',
        size: '46x46',
      });
    },
    isChecked(v: Category) {
      return this.value.some((id) => id === v.id);
    },
    totalAtomsCount(category: Category): number | null {
      return category.totals.atoms || null;
    },
  },
});
