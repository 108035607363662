import { ref, watch } from 'vue';

export function useInput<T>(startValue: T, onInput: (value: T) => void) {
  const input = ref<T>(startValue);

  watch(input, (value) => {
    onInput(value as T);
  });

  return {
    input,
  };
}
