
import { defineComponent, PropType } from 'vue';
import { IContentProvider, MY_COMPANY_PROVIDER } from '@/domains/content-provider';

export default defineComponent({
  name: 'MyCompanyProviderFilter',
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: [] as string[],
    },
    items: {
      type: Array as PropType<IContentProvider[]>,
      default: [] as IContentProvider[],
    },
  },
  computed: {
    myCompanyProviderValue(): string {
      return MY_COMPANY_PROVIDER.id;
    },
    externalProvidersIds(): string[] {
      return this.items.filter((provider) => provider.id !== MY_COMPANY_PROVIDER.id)
        .map((p) => p.id);
    },
    /**
     * Проверка выбраны ли все внешние провайдеры
     * @returns {boolean}
     */
    isExternalProvidersSelected(): boolean {
      if (!this.externalProvidersIds.length || !this.internalValue) return false;

      return this.externalProvidersIds.every((external) => {
        return this.internalValue.includes(external);
      });
    },

    internalValue: {
      get(): string[] {
        return this.value;
      },
      set(ids: string[]) {
        this.$emit('input', ids === null ? [] : ids);
      },
    },
  },
  methods: {
    /**
     * Обработчик нажатия на чекбокс внешних провайдеров:
     * Если выбрана ранее моя компания -> Снимается выбор "Моя комания"
     * Если выбраны все или частично внешние провайдеры - они снимаются
     */
    externalProvidersClickHandler() {
      const isMyCompanyProviderChecked = this.internalValue.includes(MY_COMPANY_PROVIDER.id);

      if (!this.isExternalProvidersSelected) {
        this.internalValue = isMyCompanyProviderChecked
          ? [...this.externalProvidersIds]
          : this.externalProvidersIds;
      } else {
        this.internalValue = isMyCompanyProviderChecked ? [MY_COMPANY_PROVIDER.id] : [];
      }
    },

    /**
     * Обработчик нажатия на чекбокс провайдера моей компании:
     * Если выбрана ранее моя компания, она снимается.
     * Снимается выбор со всех внешних провайдеров и выделяется "Моя компания"
     */
    internalProviderHandler(value: string[]) {
      const isMyCompanyProviderChecked = value.includes(MY_COMPANY_PROVIDER.id);
      this.internalValue = isMyCompanyProviderChecked ? [MY_COMPANY_PROVIDER.id] : value;
    },
  },
});
