
import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
  name: 'MenuDropdownItem',
  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String as PropType<String | TranslateResult>,
      default: '',
    },
    /**
     * цвет иконки и текста. Достаточно передать основной оттенок - red/green/blue и тд
     */
    color: {
      type: String,
      default: 'mono-100',
    },
  },
});
