
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'DurationAsidePicker',
  props: {
    value: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value(v) {
      this.internalValue = v;
    },
    internalValue(v) {
      this.$emit('input', v);
    },
  },
});
