
import Vue, { PropType } from 'vue';
import SortOrderBtn from '@/components/ui/SortOrderBtn/SortOrderBtn.vue';
import MenuDropdown from '@/components/ui/MenuDropdown/MenuDropdown.vue';
import MenuDropdownItem from '@/components/ui/MenuDropdown/MenuDropdownItem/MenuDropdownItem.vue';
import { AtomSort } from '@/domains/atom';

export default Vue.extend({
  name: 'SortOrderProgramsMenu',
  components: {
    SortOrderBtn,
    MenuDropdownItem,
    MenuDropdown,
  },
  props: {
    value: {
      type: String as PropType<string>,
      default: '',
    },
  },
  computed: {
    actionButtons() {
      return [
        {
          text: this.$t('SortOrderProgramsMenu.alphabet'),
          icon: 'fal fa-sort-alpha-up',
          color: 'tt-light-mono-100',
          action: () => this.$emit('input', AtomSort.NAME_ASC),
        },
        {
          text: this.$t('SortOrderProgramsMenu.date'),
          icon: 'fal fa-calendar-alt',
          color: 'tt-light-mono-100',
          action: () => this.$emit('input', AtomSort.UPDATED_AT_DESC),
        },
      ];
    },
  },
});
