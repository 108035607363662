<template>
  <div class="d-flex justify-center">
    <div v-intersect="infiniteScrolling" />
    <VProgressCircular
      v-if="loading"
      indeterminate
      color="primary"
    />
  </div>
</template>
<script>
export default {
  name: 'InfinityScroll',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
  },
  computed: {
    canLoadMore() {
      return !this.loading && this.total >= this.page * this.limit;
    },
  },
  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
      if (this.canLoadMore && isIntersecting) {
        this.$emit('load');
      }
    },
  },
};
</script>
