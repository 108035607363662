
import { defineComponent, PropType } from 'vue';
import { IContentProvider } from '@/domains/content-provider';
import { useInput } from '@/composables';
import MyCompanyProviderFilter from '@/components/catalogue/ContentProviderAsidePicker/MyCompanyProviderFilter.vue';

export default defineComponent({
  name: 'ContentProviderAsidePicker',
  components: { MyCompanyProviderFilter },
  props: {
    value: {
      type: Array as PropType<IContentProvider['id'][]>,
      default: [] as IContentProvider['id'][],
    },
    items: {
      type: Array as PropType<IContentProvider[]>,
      default: [] as IContentProvider[],
    },
  },
  setup(props, { emit }) {
    const { input: currentValue } = useInput<string[]>(props.value, (val) => emit('input', val));
    return {
      currentValue,
      onInput(value: string[] | null) {
        currentValue.value = value || [];
      },
    };
  },
});
