<template>
  <VCol>
    <VImg
      :src="require('@/assets/img/atoms-empty-filtered-list.png')"
      :width="153"
      class="mx-auto mb-6"
    />
    <div class="tt-text-title-2 mb-2 text-center">
      {{ $t('EmptyProgramsFilteredList.title') }}
    </div>
    <div class="tt-text-body-1 tt-light-mono-64--text text-center">
      {{ $t('EmptyProgramsFilteredList.subtitle') }}
    </div>
  </VCol>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'EmptyAtomsFilteredList',
});
</script>
