import dayjs, { ConfigType } from 'dayjs';

function getRemainingTime(startedAt: ConfigType, seconds: number, now: ConfigType = Date.now()) {
  if (seconds === 0) {
    return 0;
  }

  const differenceSeconds = -dayjs(startedAt).diff(dayjs(now)) / 1000;

  return Math.max(seconds - differenceSeconds, 0);
}

function getRemainingTimePercent(remainingSeconds: number, time: number): number {
  const percent = (remainingSeconds * 100) / time;
  return percent < 0 ? 0 : percent;
}

export { getRemainingTime, getRemainingTimePercent };
